/* eslint-disable no-fallthrough */
require('@vue-storefront/core').registerLogger((level) => {
  const logging = {
    debug: () => undefined,
    info: () => undefined,
    warn: () => undefined,
    error: () => undefined,
  };

  switch (level) {
    case 'debug':
      logging.debug = (data) => console.log(JSON.stringify({ ...(typeof data === 'string' ? { message: data } : data), type: 'debug' }));
    case 'error':
      logging.error = (data) => console.log(JSON.stringify({ ...(typeof data === 'string' ? { message: data } : data), type: 'error' }));
    case 'warn':
      logging.warn = (data) => console.log(JSON.stringify({ ...(typeof data === 'string' ? { message: data } : data), type: 'warn' }));
    case 'info':
    default:
      logging.info = (data) => console.log(JSON.stringify({ ...(typeof data === 'string' ? { message: data } : data), type: 'info' }));
  }

  return logging;
}, process.env.LOG_LEVEL || 'info');

const { CT_COOKIE_NAME } = require('@vue-storefront/commercetools-api');
const { Logger } = require('@vue-storefront/core');

const allowedLogLevels = ['error', 'warn', 'info', 'debug'];

const generateTraceId = (prefix = 'bff') => `${prefix}-${Date.now()}-${Math.floor(Math.random() * 90000) + 10000}`; // prefix-timestampInMili-fiveRandomNumbers

const getCommercetoolsCookie = (request) => {
  const cookieJson = request.cookies[CT_COOKIE_NAME];
  try {
    return cookieJson ? JSON.parse(cookieJson) : null;
  } catch (err) {
    Logger.error(`Cookie ${CT_COOKIE_NAME} was not parseable.`, {
      headers: JSON.stringify(request.headers, null, 2),
      cookies: JSON.stringify(request.cookies, null, 2),
    });
  }

  return {
    access_token: null,
  };
};

const BFFResponse = (prefix, { traceId } = {}) => {
  const response = {
    data: {},
    errors: [],
  };

  return {
    setData(data) {
      response.data = data;
      return this;
    },
    getData() {
      return response.data;
    },
    clearData() {
      response.data = [];
      return this;
    },
    setErrors(errors, extraLogData, logLevel = 'error') {
      if (!allowedLogLevels.includes(logLevel)) {
        throw new Error(`Invalid log level: ${logLevel}`);
      }

      if (errors) {
        const logMetadata = typeof extraLogData === 'string' ? { extraMessage: extraLogData } : { ...extraLogData };
        Logger[logLevel]({
          message: `${prefix} :: ${typeof errors === 'string' ? errors : JSON.stringify(errors, null, 2)}`,
          timestamp: new Date(),
          ...(traceId ? { 'x-trace-id': traceId } : {}),
          ...logMetadata,
        });

        response.errors.push(...(Array.isArray(errors) ? errors : [errors]));
      }

      return this;
    },
    getErrors() {
      return response.errors.filter(Boolean);
    },
    addErrors(errors, extraLogData, logLevel = 'error') {
      if (!allowedLogLevels.includes(logLevel)) {
        throw new Error(`Invalid log level: ${logLevel}`);
      }

      if (errors) {
        const logMetadata = typeof extraLogData === 'string' ? { extraMessage: extraLogData } : { ...extraLogData };
        Logger[logLevel]({
          message: `${prefix} :: ${typeof errors === 'string' ? errors : JSON.stringify(errors, null, 2)}`,
          timestamp: new Date(),
          ...(traceId ? { 'x-trace-id': traceId } : {}),
          ...logMetadata,
        });

        response.errors.push(...(typeof errors === 'string' ? [errors] : errors));
      }

      return this;
    },
    clearErrors() {
      response.errors = [];
      return this;
    },

    get() {
      return {
        ...response,
        data: this.getData(),
        errors: this.getErrors(),
      };
    },
  };
};

const removeEmojis = (obj) => {
  if (typeof obj !== 'object') {
    throw new Error('Input should be an object');
  }

  const sanitizedObject = JSON.parse(JSON.stringify(obj, (key, value) => {
    if (typeof value === 'string') {
      return value.replace(/\p{Extended_Pictographic}/u, '');
    }
    return value;
  }));

  return sanitizedObject;
};

const authorEnvRegexMatch = /^((https?:\/\/)?vsf-author.(test|stage|prod)\.kik(?:-prod)?\.magnolia-platform\.com)$/;
const isAuthorEnv = (req) => authorEnvRegexMatch.test(req.headers.host);

const trimFormFields = (formValue) => {
  const processedForm = {};
  // Get all the keys (field names) of the form object
  Object.keys(formValue).forEach((field) => {
    // Ensure the value is a string before trimming
    if (typeof formValue[field] === 'string') {
      processedForm[field] = formValue[field].trim();
    }
  });

  return processedForm;
};

const sanitizeFormData = (address) => {
  let sanitizedAddress = {};
  sanitizedAddress = removeEmojis(address);
  sanitizedAddress = trimFormFields(sanitizedAddress);
  return sanitizedAddress;
};

module.exports = {
  getCommercetoolsCookie,
  BFFResponse,
  removeEmojis,
  generateTraceId,
  isAuthorEnv,
  trimFormFields,
  sanitizeFormData,
  Logger,
};
