import { render, staticRenderFns } from "./SfModal.vue?vue&type=template&id=125d998d&"
import script from "./SfModal.vue?vue&type=script&lang=ts&"
export * from "./SfModal.vue?vue&type=script&lang=ts&"
import style0 from "./SfModal.vue?vue&type=style&index=0&id=125d998d&prod&lang=scss&"
import style1 from "./SfModal.vue?vue&type=style&index=1&id=125d998d&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports