
import {SfOverlay, SfBar, SfButton, SfIcon } from "@storefront-ui/vue"
import { focusTrap } from "../../../utilities/directives";
import { clickOutside } from "../../../utilities/directives";
import { isClient } from "../../../utilities/helpers";
import {
  getCurrentInstance,
  onBeforeUnmount,
  toRef,
  watch,
  ref,
  defineComponent,
} from "@nuxtjs/composition-api";

export default defineComponent({
  name: 'SfModal',
  directives: { focusTrap, clickOutside },
  components: {
    SfBar,
    SfOverlay,
    SfIcon,
    SfButton,
  },
  model: {
    prop: 'visible',
    event: 'close',
  },
  props: {
    /**
     * Heading title of the modal
     */
    title: {
      type: String,
      default: '',
    },
    /**
     * Visibility of the modal
     */
    visible: {
      type: Boolean,
      default: false,
    },
    /**
     * Cross closing modal button
     */
    cross: {
      type: Boolean,
      default: true,
    },
    /**
     * Whether to show the overlay
     */
    overlay: {
      type: Boolean,
      default: true,
    },
    /**
     * If true clicking outside will not dismiss the modal
     */
    persistent: {
      type: Boolean,
      default: false,
    },
    /**
     * overlay transition effect
     */
    transitionOverlay: {
      type: String,
      default: 'sf-fade',
    },
    /**
     * overlay transition effect
     */
    transitionModal: {
      type: String,
      default: 'sf-fade',
    },
  },
  setup(props) {
    const visible = toRef(props, 'visible');
    const staticClass = ref(null);
    const className = ref(null);

    const vm = getCurrentInstance();
    const root = vm.proxy.$root;

    const close = () => {
      root.$emit("close", false);
    };

    const checkPersistence = ()  => {
      if (!props.persistent) {
        close();
      }
    };

    const keydownHandler = (e) => {
      if (e.key === "Escape" || e.key === "Esc" || e.keyCode === 27) {
        close();
      }
    };

    const classHandler = () => {
      const component = getCurrentInstance();
      const vnode = component?.proxy.$vnode;

      if (staticClass.value !== vnode.data.staticClass) {
        staticClass.value = vnode.data.staticClass;
      }
      if (className.value !== vnode.data.class) {
        className.value = vnode.data.class;
      }
    };

    watch(visible, async (visibleValue) => {
      if (!isClient) return;
      if (visibleValue) {
        document.body.style.position = 'fixed';
        document.addEventListener("keydown", keydownHandler);
      } else {
        document.body.style.position = '';
        document.removeEventListener("keydown", keydownHandler);
      }
    });

    onBeforeUnmount(() => {
      document.body.style.position = '';
    })

    return {
      classHandler,
      keydownHandler,
      checkPersistence,
      close,
      staticClass,
      className,
    };
  },
});
