import {
  computed, ref, shallowRef, useContext,
} from '@nuxtjs/composition-api';
import { v4 as uuidV4 } from 'uuid';
import { Cart } from '@vue-storefront/commercetools-api';

import { useCart } from '~/composables/custom/useCart/useCart';
import { useUnzer } from '~/composables/custom/useUnzer/useUnzer';
import { useTracking } from '~/composables/custom/useTracking';
import { useUser } from '~/composables/custom/useUser/useUser';
import useUiNotification, { UiNotification } from '~/composables/useUiNotification';
import { useBFF } from '~/composables/useBFF/useBFF';
import { userGetters } from '~/composables/getters';
import { getErrorMessage } from '~/helpers/utils/getErrorMessage';

export const expectedErrorCodes = ['paymentCanceled', 'paymentFailed', 'rollbackError', 'addressValidationError'];

export interface PaymentMethod {
  name: string;
  text: string,
  label: string,
  enabled: boolean,
  minThreshold: unknown,
  failedConditions: string[],
  failedConditionsMessages: {
    minThreshold: unknown,
    shippingIsBilling: unknown,
    customerIsBilling: unknown,
    clickAndCollectPayment: unknown,
  },
  logo: string | undefined,
  provider: string,
  method: string,
}

interface AddPaymentRequest {
  orderNumber: string| undefined,
  name: string,
  method: string,
  provider: string,
  birthDate?: string,
}

interface CreateOrderRequest {
  orderNumber: string | undefined,
  threatMetrixId?: string,
  resourceId?: string,
  cardDetails?: CardDetails
}
interface CardDetails {
  number: string;
  brand: string;
  expiryDate: string;
  cardHolder: string;
}
type PaymentErrorTypes = 'addPayment' | 'createPaymentOrder' | 'loadPayments' | 'addPaymentOption' | 'checkCreditCardCall';

const invoiceThreatMetrixSessionId = ref<string>();
const loading = ref(false);
const card = ref(null);
const availablePaymentOptions = shallowRef<any>(undefined);

export const usePayment = () => {
  const { $ct } = useBFF();
  const { i18n, $config, $domain } = useContext();
  const {
    cart,
    setCart,
    refresh,
  } = useCart();
  const { send } = useUiNotification();
  const {
    errors: unzerError,
    initializeUnzerPayment,
  } = useUnzer();
  const { trackError } = useTracking();
  const { user } = useUser();
  const isPaymentInAdvanceBlocked = computed(() => userGetters.isPaymentInAdvanceBlocked(user.value));
  const paymentProviderRedirectUrl = ref<string | undefined>();
  const paymentCreditCard = ref<any>();
  const paymentInCart = shallowRef<unknown>();
  const errors = shallowRef<Record<PaymentErrorTypes, string>>({
    loadPayments: undefined,
    addPaymentOption: undefined,
    addPayment: undefined,
    createPaymentOrder: undefined,
    checkCreditCardCall: undefined,
  });

  const generateThreatMetrixSessionId = () => {
    invoiceThreatMetrixSessionId.value = `kik_${uuidV4()}-${Date.now()}`;

    return invoiceThreatMetrixSessionId.value;
  };

  const loadPayments = async () => {
    try {
      loading.value = true;
      const orderNumber = cart.value?.key ?? undefined;
      const {
        data,
        errors: loadPaymentErrors = [],
      } = await $ct.loadPaymentMethods<PaymentMethod[]>({ orderNumber });

      if (loadPaymentErrors.length) {
        throw new Error(loadPaymentErrors.join(', '));
      }

      if (!orderNumber) { // no order number provided means OPS will generate one and cart version got bumped
        await refresh();
      }

      return transformFailedConditions(data);
    } catch (err) {
      errors.value.loadPayments = err.message;
      trackError(errors.value.loadPayments);
    } finally {
      loading.value = false;
    }

    return undefined;
  };

  const addPaymentOption = async (selectedPayment: PaymentMethod, birthDate?: string) => {
    try {
      loading.value = true;
      errors.value.addPayment = undefined;

      const {
        name,
        method,
        provider,
      } = selectedPayment;
      const orderNumber = cart.value?.key ?? undefined;

      const payload: AddPaymentRequest = {
        name,
        orderNumber,
        birthDate,
        method,
        provider,
      };

      const { data, errors: apiErrors } = await $ct.setPaymentOption<Pick<Cart, 'paymentInfo' | 'version'>>(payload);

      if (apiErrors.length) {
        throw new Error(apiErrors.join(', '));
      }

      await setCart({ ...cart.value, ...data });
      paymentInCart.value = data.paymentInfo;
    } catch (err) {
      errors.value.addPaymentOption = err.message;
      trackError(errors.value.addPaymentOption);
      sendError('somethingWentWrong');
    } finally {
      loading.value = false;
    }
  };
  const createPaymentOrder = async (paymentOption: Pick<PaymentMethod, 'method' | 'provider'>, { isPaypalExpress = false }: { isPaypalExpress?: boolean } = { isPaypalExpress: false }) => {
    paymentProviderRedirectUrl.value = undefined;
    errors.value.createPaymentOrder = undefined;
    try {
      loading.value = true;
      const isCreditCard = paymentOption?.method === $config?.payment?.methods?.CARD;
      const resourceId = isCreditCard ? paymentCreditCard.value.id : await initialize(paymentOption);
      // Temporary solution for PayPal Express in Poland
      const paypalPaymentProvider = $domain.countryCode === 'PL' ? $config.payment.providers.PAYPAL_ECOM : $config.payment.providers.PAYPAL;

      const payload: CreateOrderRequest = {
        orderNumber: cart.value?.key,
        threatMetrixId: invoiceThreatMetrixSessionId.value,
        resourceId,
        ...(isPaypalExpress ? { paymentMethodName: `${paypalPaymentProvider}-${$config.payment.methods.PAYPAL_EXPRESS}` } : {}),
        ...(isCreditCard ? {
          cardDetails: {
            number: paymentCreditCard.value.number,
            expiryDate: paymentCreditCard.value.expiryDate,
            cardHolder: paymentCreditCard.value.cardHolder,
            brand: paymentCreditCard.value.cardBrand,
          },
        } : {}),
      };

      const { data, errors: apiErrors = [] } = await $ct.createOrder(payload);
      if (data?.errorCode) {
        if (data.errorCode === 'cartValidationError' && data.errors.length) {
          const errorsSize = data.errors.length;
          data.errors?.forEach((error) => {
            const errorMessage = getErrorMessage(error?.errorCode);
            errors.value.createPaymentOrder = error?.errorCode;
            if (error?.errorCode === 'priceMismatchAfterRecalculate') { // send warning notification
              send({
                ttlInMs: 10000,
                message: 'priceMismatchAfterRecalculate',
                type: 'warning',
              });
            } else {
              sendError(errorMessage, { ttlInMs: errorsSize * 10000 });
            }
          });
          await refresh();
          return;
        }

        const errorMessage = getErrorMessage(data.errorCode);
        errors.value.createPaymentOrder = data.errorCode;
        sendError(errorMessage, { ttlInMs: 10000 });
        return;
      }

      if (apiErrors.length) {
        errors.value.createPaymentOrder = apiErrors.join(', ');
        trackError(errors.value.createPaymentOrder);
        throw new Error(errors.value.createPaymentOrder);
      }

      if (data?.redirectUrl) {
        paymentProviderRedirectUrl.value = data.redirectUrl;
      }
    } catch (err) {
      errors.value.createPaymentOrder = err.message;
      sendError('somethingWentWrong');
    } finally {
      loading.value = false;
    }
  };

  const initialize = async (paymentOption: Pick<PaymentMethod, 'method' | 'provider'>) => {
    const { UNZER: unzerProvider, UNZER_ECOM: unzerEcomProvider } = $config.payment.providers;
    if (![unzerProvider, unzerEcomProvider].includes(paymentOption?.provider)) {
      return undefined;
    }

    try {
      if (!paymentOption?.method) {
        throw new Error('Missing payment method value');
      }

      const resourceId = await initializeUnzerPayment(paymentOption);
      if (unzerError.value.token || !resourceId) {
        throw new Error('Failed to authorize provider');
      }

      return resourceId;
    } catch (error) {
      errors.value.createPaymentOrder = error.message;
      throw new Error(errors.value.createPaymentOrder);
    }
  };

  const transformFailedConditions = (paymentOptions: PaymentMethod[]) => {
    availablePaymentOptions.value = paymentOptions
      .filter((option) => (isPaymentInAdvanceBlocked.value ? option.name !== 'vorkasse-vorkasse' : true))
      .map((paymentMethod) => {
        const { failedConditions = [] } = paymentMethod;
        const failedConditionsMessages = failedConditions.reduce((acc, failedCondition) => {
          acc[failedCondition] = conditionMessages.getFailedConditionMessage(paymentMethod, failedCondition);
          return acc;
        }, {});

        return {
          ...paymentMethod,
          failedConditionsMessages,
        };
      });
  };
  const sendError = (message, options?: Pick<UiNotification, 'ttlInMs'>) => {
    send({
      ...(options ?? {}),
      message,
      type: 'danger',
    });
  };

  const conditionMessages = {
    minThreshold: {
      getMessage: (paymentMethod) => i18n.t('paymentMethodLimit', {
        amount: paymentMethod?.minThreshold?.value,
        paymentOption: i18n.t(paymentMethod.name),
      }),
    },
    shippingIsBilling: {
      getMessage: () => i18n.t('This payment method is only possible for delivery to the billing address'),
    },
    customerIsBilling: {
      getMessage: () => i18n.t('CUSTOMER_IS_BILLING_FAILED_CONDITION_PLACE_HOLDER_MESSAGE_KEY'),
    },
    clickAndCollectPayment: {
      getMessage: () => i18n.t('CLICK_AND_COLLECT_PAYMENT_FAILED_CONDITION_PLACE_HOLDER_MESSAGE_KEY'),
    },
    getFailedConditionMessage(paymentMethod, failedCondition) {
      return conditionMessages[failedCondition]?.getMessage(paymentMethod);
    },

  };

  const renderCreditCardForm = async (_clientId, _locales) => {
    // eslint-disable-next-line new-cap
    const unzerInstance = new window.unzer(_clientId, { locales: _locales });
    // Creating an Card instance
    card.value = unzerInstance.Card();

    // Rendering input field card number
    card.value.create('number', {
      containerId: 'card-element-id-number',
      onlyIframe: false,
    });

    // Rendering input field card expiry
    card.value.create('expiry', {
      containerId: 'card-element-id-expiry',
      onlyIframe: false,
    });

    // Rendering input field card cvc
    card.value.create('cvc', {
      containerId: 'card-element-id-cvc',
      onlyIframe: false,
    });

    card.value.create('holder', {
      containerId: 'card-element-id-holder',
      onlyIframe: false,
    });
  };

  const checkCreditCardCall = async () => {
    errors.value.checkCreditCardCall = undefined;
    loading.value = true;
    paymentCreditCard.value = await card.value.createResource()
      .then((res) => res)
      .catch((err) => {
        errors.value.checkCreditCardCall = err.message;
        sendError(err.message);
      }).finally(() => {
        loading.value = false;
      });
  };
  return {
    errors: computed(() => errors.value),
    loading: computed(() => loading.value),
    availablePaymentOptions: computed(() => availablePaymentOptions.value),
    paymentInCart: computed(() => paymentInCart.value),
    invoiceThreatMetrixSessionId: computed(() => invoiceThreatMetrixSessionId.value),
    resetError: (key: PaymentErrorTypes) => { errors.value[key] = undefined; },
    loadPayments,
    addPaymentOption,
    createPaymentOrder,
    generateThreatMetrixSessionId,
    renderCreditCardForm,
    checkCreditCardCall,
    paymentCreditCard,
    card,
    redirectUrl: computed(() => paymentProviderRedirectUrl.value),
  };
};
