import { sharedRef } from '@vue-storefront/core';
import { computed, ref, useContext } from '@nuxtjs/composition-api';
import { useAppLocale } from '../useAppLocale';

export const useTagsLoader = () => {
  const result = sharedRef(undefined, 'useTagsLoader-result');
  const { $config } = useContext();
  const { country } = useAppLocale();
  const loading = ref(false);
  const error = ref({
    content: false,
    contentStatusCode: undefined,
  });

  const fetchTags = async <T>(): Promise<T | undefined> => {
    const url = `${$config.mgnlRestTags}/${country}`;

    loading.value = true;
    error.value.content = false;
    try {
      const resp = await fetch(url);

      if (!resp.ok) {
        error.value.content = true;
        error.value.contentStatusCode = resp.status;

        return undefined;
      }

      result.value = await resp.json();
    } catch (err) {
      error.value.content = err.message;
    } finally {
      loading.value = false;
    }

    if (result.value) {
      result.value = Object.entries(result.value).filter((attributes) => !(attributes[0].includes('@') || attributes[0].includes('mgnl'))).map((entry) => entry[1]);
    }

    return result.value;
  };

  return {
    result: computed(() => result.value),
    error: computed(() => error.value),
    loading: computed(() => loading.value),
    fetchTags,
  };
};
