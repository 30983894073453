/* eslint-disable no-unused-vars, no-shadow */
export enum Attributes {
    WGR = 'wgr',
    EKB = 'ekb',
    SIZE = 'size',
    SIZE_ORDER = 'size_order',
    COLOR_CODE = 'color_code',
    COLOR = 'color',
    DANGER_ICONS = 'danger_icons',
    MANUFACTURER_INFOS = 'manufacturer_infos',
    SIGNAL_WORD = 'signalword',
    HAZARD_NOTICE = 'hazard_notice',
    MATERIAL = 'material',
    BULLET_POINTS = 'bulletpoints',
    SIEGEL = 'siegel',
    PRODUCT_DATA_SHEETS = 'productdata_sheets',
    ENERGY_EFFICIENCY_CLASS = 'energy_efficiency_class',
    ENERGY_EFFICIENCY_LABEL = 'energy_efficiency_label',
    DETAIL_NAME = 'detailname',
    BRAND = 'brand',
    CAPACITY_QUANTITY = 'capacity_quantity',
    CAPACITY_UOM = 'capacity_uom',
    SEALS = 'seals',
    ONLINE = 'online',
    ONLINE_DATE_SKU = 'online_date_sku',
    COLOR_NUMBER = 'color_number',
    SAFETY_INSTRUCTIONS = 'safety_instructions',
    DISCOUNT_SALE_VALUE = 'discount_sale_value'
}

export const getFacets = (country: string) => Object.values(Facets).concat((`${country.toLocaleLowerCase()}_marketing_tag` as Facets));

export enum Facets {
    SEARCH_SIZE = 'search_size',
    SEARCH_COLOR = 'search_color',
    PATTERN = 'pattern',
    SINGLE_MULTICOLOR = 'single_multicolor',
    PRINTS = 'prints',
    WASHING = 'washing',
    FIT_MANUAL = 'fit_manual',
    CUT = 'cut',
    DRESS_LENGTH = 'dress_length',
    SKIRT_LENGTH = 'skirt_length',
    TROUSER_LEG_LENGTH = 'trouser_leg_length',
    SLEEVE_LENGTH = 'sleeve_length',
    STYLE = 'style',
    CLOTHING_STYLE = 'clothing_style',
    INTERIOR_STYLE = 'interior_style',
    WAISTBAND_HEIGHT = 'waistband_heigth',
    CUTOUT = 'cutout',
    COLLAR_SHAPE = 'collar_shape',
    CUT_SHAPE = 'cup_shape',
    PRICE = 'price',
}

export enum CustomFilters {
    PRICE_RANGE_MIN = 'min',
    PRICE_RANGE_MAX = 'max',
    SORT = 'sort',
    SEARCH = 'search',
    SALE_AND_NEW = 'filters'
}

export enum SortType {
    NEWEST = 'newest',
    PRICE_ASC = 'price_ascending',
    PRICE_DESC = 'price_descending',
    DISCOUNT_SALE = 'discount'
}

type MapAttributesToTypes = {
    [Attributes.WGR]?: string;
    [Attributes.EKB]?: string;
    [Attributes.SIZE]?: string;
    [Attributes.SIZE_ORDER]?: string;
    [Attributes.COLOR_CODE]?: string;
    [Attributes.COLOR]?: string;
    [Attributes.DANGER_ICONS]?: string[];
    [Attributes.MANUFACTURER_INFOS]?: string;
    [Attributes.SIGNAL_WORD]?: string;
    [Attributes.HAZARD_NOTICE]?: string;
    [Attributes.MATERIAL]?: string[];
    [Attributes.BULLET_POINTS]?: string[];
    [Attributes.SIEGEL]?: {
        label: string
    }[];
    [Attributes.PRODUCT_DATA_SHEETS]?: string;
    [Attributes.ENERGY_EFFICIENCY_CLASS]?: {
        label: string;
    };
    [Attributes.ENERGY_EFFICIENCY_LABEL]?: string;
    [Attributes.DETAIL_NAME]?: string;
    [Attributes.BRAND]?: {
        id: string;
    };
    [Attributes.CAPACITY_QUANTITY]?: string;
    [Attributes.CAPACITY_UOM]?: string;
    [Attributes.SEALS]?: any[];
    [Attributes.ONLINE]: boolean;
    [Attributes.ONLINE_DATE_SKU]: string;
    [Attributes.COLOR_NUMBER]?: string;
    [Attributes.SAFETY_INSTRUCTIONS]?: string;
    [Attributes.DISCOUNT_SALE_VALUE]?: string;
}

export type TAttributes = {
    [key in Attributes]?: MapAttributesToTypes[key];
}

export interface Variant {
    id: string,
    key?: string,
    sku: string,
    availability: {
        isOnStock: boolean,
        availableQuantity: number,
        channels?: {
            results: {
                channel: {
                    id: string,
                    roles: unknown,
                },
                availability: {
                    availableQuantity: string;
                    isOnStock: boolean;
                }
            }[]
        },
    }
    images: {
        url: string,
        dimensions: {
            width: number,
            height: number,
        },
        label: string
    }[],
    price: {
        final: number,
        strike?: number,
        unit?: string,
        salePercentage?: string,
        store?: number,
        deposit?: number,
        lowestTotalPrice?: {
            value: number;
            percentage: number;
        };
    },
    attributes: TAttributes;
    hrefLang: any;
    isOnlineSale: boolean;
}

export interface Product {
    id: string;
    brandName?: string;
    key?: string;
    slug: string;
    name?: string;
    description?: string;
    masterVariantId: string;
    variants: Variant[];
    isPlusSize?: boolean;
    isOnlineOnly?: boolean;
    isNew: boolean;
}

export interface ProductTeaser extends Variant{
    id: string;
    brandName?: string;
    key?: string;
    slug: string;
    name?: string;
    description?: string;
    isPlusSize?: boolean;
    isNew: boolean;
    colors: string[];
    isOnSale: boolean;
    isOnlineOnly?: boolean;
    variants?: Variant[];
}
