var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('article',{staticClass:"stage-teaser",class:`kik-${_vm.position}`},[_c('section',{staticClass:"stage-teaser__content",class:`kik-${_vm.imagePosition}`,style:({ backgroundColor: _setup.backgroundColor })},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"stage-teaser__top-title",style:({ color: _setup.topTitleColor})},[_vm._v("\n        "+_vm._s(_vm.topTitle)+"\n      ")]),_vm._v(" "),(_vm.title)?_c(_vm.headingTag,{tag:"component",staticClass:"stage-teaser__title",style:({ color: _setup.titleColor })},[_vm._v("\n        "+_vm._s(_vm.title)+"\n      ")]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"stage-teaser__subtitle",style:({ color: _setup.subTextColor })},[_vm._v("\n        "+_vm._s(_vm.subText)+"\n      ")]),_vm._v(" "),(_setup.link && _setup.ctaText)?[_c(_setup.TargetLink,{attrs:{"anchor-props":{
            style: {
              color: _setup.ctaTextColor,
              backgroundColor: _setup.buttonColor,
              border: `1px solid ${_setup.borderColor}`,
            },
          },"link":_setup.link}},[_vm._v("\n          "+_vm._s(_setup.ctaText)+"\n        ")])]:_vm._e()],2)]),_vm._v(" "),_c(_setup.TargetLink,{staticClass:"stage-teaser__image",attrs:{"link":_setup.imgLink}},[_c('nuxt-img',{attrs:{"src":_setup.imgSrc,"srcset":_setup.imgSrcSet,"alt":_setup.imgAlt,"width":_setup.imgDimensions.width,"height":_setup.imgDimensions.height,"title":_setup.imgTitle,"loading":_vm.imageLoadType,"preload":_vm.imageLoadType === 'eager',"fetchpriority":_vm.imageLoadType === 'eager' ? 'high' : 'low'}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }