import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _753adfda = () => interopDefault(import('../pages/Product.vue' /* webpackChunkName: "" */))
const _5caf70c5 = () => interopDefault(import('../pages/_id.vue' /* webpackChunkName: "" */))
const _11b04b23 = () => interopDefault(import('../pages/Category.vue' /* webpackChunkName: "" */))
const _4adc87e8 = () => interopDefault(import('../pages/MyAccount.vue' /* webpackChunkName: "" */))
const _7c39ebb6 = () => interopDefault(import('../pages/Filialfinder.vue' /* webpackChunkName: "" */))
const _4f77776b = () => interopDefault(import('../pages/Checkout.vue' /* webpackChunkName: "" */))
const _4bcc87a2 = () => interopDefault(import('../pages/Checkout/Shipping.vue' /* webpackChunkName: "" */))
const _5b3a65b7 = () => interopDefault(import('../pages/Checkout/Billing.vue' /* webpackChunkName: "" */))
const _0c2c907c = () => interopDefault(import('../pages/Checkout/Payment.vue' /* webpackChunkName: "" */))
const _355a5349 = () => interopDefault(import('../pages/Checkout/ThankYou.vue' /* webpackChunkName: "" */))
const _67d0a035 = () => interopDefault(import('../pages/ResetPassword.vue' /* webpackChunkName: "" */))
const _32acef2c = () => interopDefault(import('../pages/Wishlist.vue' /* webpackChunkName: "" */))
const _d69d91b2 = () => interopDefault(import('../pages/SignIn.vue' /* webpackChunkName: "" */))
const _510976c5 = () => interopDefault(import('../pages/Cart.vue' /* webpackChunkName: "pages/Cart" */))
const _32f9dd26 = () => interopDefault(import('../pages/Checkout/SwitchView.vue' /* webpackChunkName: "pages/Checkout/SwitchView" */))
const _7333e7e6 = () => interopDefault(import('../pages/Checkout/form/billingValidationMessages.js' /* webpackChunkName: "pages/Checkout/form/billingValidationMessages" */))
const _35347994 = () => interopDefault(import('../pages/ConfirmDelete.vue' /* webpackChunkName: "pages/ConfirmDelete" */))
const _12b1e022 = () => interopDefault(import('../pages/GuestCreateReturn.vue' /* webpackChunkName: "pages/GuestCreateReturn" */))
const _9068c492 = () => interopDefault(import('../pages/GuestOrderDetails.vue' /* webpackChunkName: "pages/GuestOrderDetails" */))
const _573efbdf = () => interopDefault(import('../pages/GuestReturnDetails.vue' /* webpackChunkName: "pages/GuestReturnDetails" */))
const _dcb5060c = () => interopDefault(import('../pages/MyAccount/BillingDetails.vue' /* webpackChunkName: "pages/MyAccount/BillingDetails" */))
const _a71a7478 = () => interopDefault(import('../pages/MyAccount/CreateReturns.vue' /* webpackChunkName: "pages/MyAccount/CreateReturns" */))
const _316429ca = () => interopDefault(import('../pages/MyAccount/DeleteProfile.vue' /* webpackChunkName: "pages/MyAccount/DeleteProfile" */))
const _5a8561da = () => interopDefault(import('../pages/MyAccount/HelpAndFAQ.vue' /* webpackChunkName: "pages/MyAccount/HelpAndFAQ" */))
const _376228f8 = () => interopDefault(import('../pages/MyAccount/MyNewsletter.vue' /* webpackChunkName: "pages/MyAccount/MyNewsletter" */))
const _04acd2fa = () => interopDefault(import('../pages/MyAccount/MyProfile.vue' /* webpackChunkName: "pages/MyAccount/MyProfile" */))
const _4c2fb46c = () => interopDefault(import('../pages/MyAccount/MyReviews.vue' /* webpackChunkName: "pages/MyAccount/MyReviews" */))
const _f6f45df2 = () => interopDefault(import('../pages/MyAccount/OrderDetails.vue' /* webpackChunkName: "pages/MyAccount/OrderDetails" */))
const _12a74a59 = () => interopDefault(import('../pages/MyAccount/OrderHistory.vue' /* webpackChunkName: "pages/MyAccount/OrderHistory" */))
const _21cb328f = () => interopDefault(import('../pages/MyAccount/ReturnDetails.vue' /* webpackChunkName: "pages/MyAccount/ReturnDetails" */))
const _ed001d8e = () => interopDefault(import('../pages/MyAccount/ReturnsOverview.vue' /* webpackChunkName: "pages/MyAccount/ReturnsOverview" */))
const _52e58a11 = () => interopDefault(import('../pages/MyAccount/ShippingDetails.vue' /* webpackChunkName: "pages/MyAccount/ShippingDetails" */))
const _db033b70 = () => interopDefault(import('../pages/Register.vue' /* webpackChunkName: "pages/Register" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/p/:slug/:id",
    component: _753adfda,
    name: "product"
  }, {
    path: "/",
    component: _5caf70c5,
    name: "home"
  }, {
    path: "/c/n/:type/:slug_1?/:slug_2?/:slug_3?/:slug_4?/:slug_5?",
    component: _11b04b23,
    meta: {"isNew":true},
    name: "category-new"
  }, {
    path: "/c/s/:type/:slug_1?/:slug_2?/:slug_3?/:slug_4?/:slug_5?",
    component: _11b04b23,
    meta: {"isSale":true},
    name: "category-sale"
  }, {
    path: "/c/:slug_1/:slug_2?/:slug_3?/:slug_4?/:slug_5?",
    component: _11b04b23,
    name: "category"
  }, {
    path: "/my-account/:pageName?",
    component: _4adc87e8,
    name: "my-account"
  }, {
    path: "/filialfinder",
    component: _7c39ebb6,
    alias: ["/znajdz-sklep","/filiaalzoeker","/vyhledat-prodejnu"],
    name: "fillialfinder"
  }, {
    path: "/checkout",
    component: _4f77776b,
    redirect: "/checkout/switchView",
    name: "checkout",
    children: [{
      path: "shipping-customer",
      component: _4bcc87a2,
      meta: {"type":"customer"},
      name: "shipping-customer"
    }, {
      path: "shipping-guest",
      component: _4bcc87a2,
      meta: {"type":"guest"},
      name: "shipping-guest"
    }, {
      path: "shipping-new-customer",
      component: _4bcc87a2,
      meta: {"type":"new-customer"},
      name: "shipping-new-customer"
    }, {
      path: "billing-customer",
      component: _5b3a65b7,
      meta: {"type":"customer"},
      name: "billing-customer"
    }, {
      path: "billing-guest",
      component: _5b3a65b7,
      meta: {"type":"guest"},
      name: "billing-guest"
    }, {
      path: "billing-new-customer",
      component: _5b3a65b7,
      meta: {"type":"new-customer"},
      name: "billing-new-customer"
    }, {
      path: "payment-customer",
      component: _0c2c907c,
      meta: {"type":"customer"},
      name: "payment-customer"
    }, {
      path: "payment-new-customer",
      component: _0c2c907c,
      meta: {"type":"new-customer"},
      name: "payment-new-customer"
    }, {
      path: "payment-guest",
      component: _0c2c907c,
      meta: {"type":"guest"},
      name: "payment-guest"
    }, {
      path: "thank-you",
      component: _355a5349,
      name: "thank-you"
    }]
  }, {
    path: "/reset-password",
    component: _67d0a035,
    name: "reset-password"
  }, {
    path: "/wishlist",
    component: _32acef2c,
    name: "wishlist"
  }, {
    path: "/signin",
    component: _d69d91b2,
    name: "signin"
  }, {
    path: "/Cart",
    component: _510976c5,
    name: "Cart"
  }, {
    path: "/Category",
    component: _11b04b23,
    name: "Category"
  }, {
    path: "/Checkout",
    component: _4f77776b,
    name: "Checkout",
    children: [{
      path: "Billing",
      component: _5b3a65b7,
      name: "Checkout-Billing"
    }, {
      path: "Payment",
      component: _0c2c907c,
      name: "Checkout-Payment"
    }, {
      path: "Shipping",
      component: _4bcc87a2,
      name: "Checkout-Shipping"
    }, {
      path: "SwitchView",
      component: _32f9dd26,
      name: "Checkout-SwitchView"
    }, {
      path: "ThankYou",
      component: _355a5349,
      name: "Checkout-ThankYou"
    }, {
      path: "form/billingValidationMessages",
      component: _7333e7e6,
      name: "Checkout-form-billingValidationMessages"
    }]
  }, {
    path: "/ConfirmDelete",
    component: _35347994,
    name: "ConfirmDelete"
  }, {
    path: "/Filialfinder",
    component: _7c39ebb6,
    name: "Filialfinder"
  }, {
    path: "/GuestCreateReturn",
    component: _12b1e022,
    name: "GuestCreateReturn"
  }, {
    path: "/GuestOrderDetails",
    component: _9068c492,
    name: "GuestOrderDetails"
  }, {
    path: "/GuestReturnDetails",
    component: _573efbdf,
    name: "GuestReturnDetails"
  }, {
    path: "/MyAccount",
    component: _4adc87e8,
    name: "MyAccount",
    children: [{
      path: "BillingDetails",
      component: _dcb5060c,
      name: "MyAccount-BillingDetails"
    }, {
      path: "CreateReturns",
      component: _a71a7478,
      name: "MyAccount-CreateReturns"
    }, {
      path: "DeleteProfile",
      component: _316429ca,
      name: "MyAccount-DeleteProfile"
    }, {
      path: "HelpAndFAQ",
      component: _5a8561da,
      name: "MyAccount-HelpAndFAQ"
    }, {
      path: "MyNewsletter",
      component: _376228f8,
      name: "MyAccount-MyNewsletter"
    }, {
      path: "MyProfile",
      component: _04acd2fa,
      name: "MyAccount-MyProfile"
    }, {
      path: "MyReviews",
      component: _4c2fb46c,
      name: "MyAccount-MyReviews"
    }, {
      path: "OrderDetails",
      component: _f6f45df2,
      name: "MyAccount-OrderDetails"
    }, {
      path: "OrderHistory",
      component: _12a74a59,
      name: "MyAccount-OrderHistory"
    }, {
      path: "ReturnDetails",
      component: _21cb328f,
      name: "MyAccount-ReturnDetails"
    }, {
      path: "ReturnsOverview",
      component: _ed001d8e,
      name: "MyAccount-ReturnsOverview"
    }, {
      path: "ShippingDetails",
      component: _52e58a11,
      name: "MyAccount-ShippingDetails"
    }]
  }, {
    path: "/Product",
    component: _753adfda,
    name: "Product"
  }, {
    path: "/Register",
    component: _db033b70,
    name: "Register"
  }, {
    path: "/ResetPassword",
    component: _67d0a035,
    name: "ResetPassword"
  }, {
    path: "/SignIn",
    component: _d69d91b2,
    name: "SignIn"
  }, {
    path: "/Wishlist",
    component: _32acef2c,
    name: "Wishlist"
  }, {
    path: "/:id?",
    component: _5caf70c5,
    name: "id"
  }, {
    path: "*",
    component: _5caf70c5,
    name: "custom"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
