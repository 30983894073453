import {
  computed, reactive, Ref, ref, shallowRef, useContext,
} from '@nuxtjs/composition-api';
import { Category } from '@vue-storefront/commercetools-api';
import { sharedRef } from '@vue-storefront/core';

import { Logger } from '~/apiExtensions/utils';
import {
  Attributes, Product, ProductTeaser, Variant,
  getFacets,
} from '~/components/Product/types/product';
import { useBFF } from '~/composables/useBFF/useBFF';
import { useUser } from '~/composables/custom/useUser/useUser';
import { useAppLocale } from '~/composables/custom/useAppLocale';
import { useCart } from '~/composables/custom/useCart/useCart';
import { cartGetters, productGetters } from '~/composables/getters';

interface IGetProducts {
  categories?: string[],
  products?: string[],
  skus?: string[],
  offset?: number,
  limit?: number,
  returnFacets: boolean;
  filterAndSortBy?: object;
  attributes?: Attributes[];
  withVariants?: boolean
}

const selectedSize = ref(null);
const validSelectedSize = ref(true);
const qty = ref(null);
const productAvailabilityAndStock = shallowRef({});
export const useProduct = (productId?: string) => {
  const { $ct } = useBFF();
  const { user } = useUser();
  const { redirect, res } = useContext();
  const { currencyCode: currency, country } = useAppLocale();

  const product = sharedRef<Product | undefined>(undefined, `get-product-by-id-${productId}`);
  const loading = ref(false);

  const errors = reactive<Record<string, string>>({
    productAvailabilityAndStock: undefined,
    getProductById: undefined,
    getProducts: undefined,
  });

  const { cart } = useCart();
  const selectedVariant: Ref<Variant> = sharedRef(undefined, `selected-variant-${productId}`);

  const setSelectedSize = (value: string) => {
    selectedSize.value = value;
    validSelectedSize.value = true;
  };
  const resetProduct = () => {
    selectedSize.value = null;
    validSelectedSize.value = true;
    qty.value = null;
  };

  const sku = computed(() => selectedVariant.value?.sku);
  const availability = computed(() => productAvailabilityAndStock.value?.[selectedVariant.value?.sku]?.availability?.availableQuantity);
  const maxQty = computed<number>(() => Math.max(availability.value ? availability.value - cartGetters.getItemQtyBySku(cart.value, sku.value) : 0));
  // @ts-ignore
  const minQty = computed(() => Math.min((availability.value ? availability.value - cartGetters.getItemQtyBySku(cart?.value, sku.value) : 0), selectedVariant.value?.minimumAmount ?? 1));

  const updateQty = () => {
    if (qty.value > maxQty.value && maxQty.value >= 0) {
      qty.value = maxQty.value;
    }
  };

  const breadCrumbDetails = computed(() => breadcrumbs.value?.reduce((acc, { text = '' }, i) => {
    acc[`item_category${i + 1}`] = text;
    return acc;
  }, {}));

  const getProductToTrack = (quantity: number) => productGetters.getTrackingProduct({ ...selectedVariant.value, name: product.value?.name, key: product.value?.key }, currency, breadCrumbDetails.value, quantity);

  const customerGroup = computed(() => user.value?.customerGroup?.key);

  const handleRedirection = ({ statusCode, redirectUrl }) => {
    if ([301, 302].includes(statusCode)) {
      redirect(statusCode, redirectUrl);
    }

    if ([404, 410].includes(statusCode)) {
      res.statusCode = statusCode;
    }
  };

  const getProductById = async (id: string, attributeFilterNames?: string[]) => {
    if (!id) {
      return;
    }
    loading.value = true;
    try {
      const { data, errors: apiErrors = [] } = await $ct.getProductById({
        id, attributeFilterNames, customerGroup: customerGroup.value,
      });

      if (apiErrors.length) {
        throw new Error(apiErrors.join('. '));
      }

      if (data?.statusCode) {
        handleRedirection(data);
      }

      product.value = data;
    } catch (err) {
      errors.getProductById = err.message;
      Logger.error(errors.getProductById);
    } finally {
      loading.value = false;
    }
  };

  const getAvailabilityAndPrices = async ({ products, skus } : { products?: string[], skus?: string[] }) => {
    if (!products?.length && !skus?.length) {
      return;
    }

    try {
      loading.value = true;
      const { data, errors: apiErrors = [] } = await $ct.getStockAndPrice({ products, skus, customerGroup: customerGroup.value });

      if (apiErrors.length) {
        throw new Error(apiErrors.join('. '));
      }

      if (productAvailabilityAndStock.value && data) {
        productAvailabilityAndStock.value = {
          ...productAvailabilityAndStock.value,
          ...data,
        };
      } else {
        productAvailabilityAndStock.value = data;
      }
    } catch (err) {
      errors.productAvailabilityAndStock = err.message;
      Logger.error(errors.productAvailabilityAndStock);
    } finally {
      loading.value = false;
    }
  };

  const getProducts = async ({
    categories, offset = 0, limit, returnFacets = false, filterAndSortBy, products, skus, attributes = [Attributes.COLOR_CODE, Attributes.DETAIL_NAME, Attributes.COLOR, Attributes.CAPACITY_QUANTITY, Attributes.CAPACITY_UOM, Attributes.SIZE, Attributes.SIZE_ORDER, Attributes.DISCOUNT_SALE_VALUE], withVariants,
  }: IGetProducts): Promise<{ items: ProductTeaser[], count: number, offset: number, total: number }> => {
    try {
      loading.value = true;
      const { data, errors: apiErrors = [] } = await $ct.getProducts({
        categories,
        attributeFilterNames: attributes,
        offset,
        limit,
        filterAndSortBy,
        returnBrand: true,
        facetsFilters: returnFacets ? getFacets(country) : [],
        products,
        skus,
        withVariants,
        customerGroup: customerGroup.value,
      });

      if (apiErrors.length) {
        throw new Error(apiErrors.join('. '));
      }

      return data;
    } catch (err) {
      errors.getProducts = err.message;
      Logger.error('Error fetching products: ', errors.getProducts);
    } finally {
      loading.value = false;
    }

    return undefined;
  };

  const getBreadcrumb = (productBreadcrumb) => {
    const category = (productBreadcrumb as Product & { categories: Category[] })?.categories?.find((cat) => cat.ancestors?.some((ancestor) => ancestor?.key?.startsWith('ShopNavigation')));
    if (!category) {
      return [];
    }
    return [...category.ancestors, { name: category.name, slug: category.slug }].slice(1).map((c, i, items) => ({
      index: i,
      text: c.name,
      link: i === 0 ? `/c/${c.slug}` : `/c/${items[0].slug}/${c.slug}`,
    }));
  };

  const breadcrumbs = computed(() => getBreadcrumb(product.value));

  const getProductsReduced = async ({ products }: any): Promise<any> => {
    if (!products.length) {
      return undefined;
    }
    try {
      loading.value = true;
      const { data, errors: apiErrors = [] } = await $ct.getProductsReduced({
        products,
      });

      if (apiErrors.length) {
        throw new Error(apiErrors.join('. '));
      }

      return data;
    } catch (err) {
      Logger.error('Error fetching products: ', err.message);
    } finally {
      loading.value = false;
    }
    return undefined;
  };

  return {
    sku,
    availability,
    maxQty,
    minQty,
    selectedColor: computed(() => selectedVariant.value?.attributes.color),
    qty: computed(() => qty.value),
    setQty: (value: number) => { qty.value = value; },
    updateQty,
    selectedVariant: computed(() => selectedVariant.value),
    setSelectedVariant: (variant: any) => { selectedVariant.value = variant; },
    selectedSize: computed(() => selectedSize.value),
    setSelectedSize,
    validSelectedSize: computed(() => validSelectedSize.value),
    setValidSelectedSize: (value: boolean) => { validSelectedSize.value = value; },
    resetProduct,
    getProductToTrack,

    loading: computed(() => loading.value),
    errors: computed(() => errors),
    product: computed(() => product.value),
    productAvailabilityAndStock: computed(() => productAvailabilityAndStock.value),

    getProductById,
    getProducts,
    getProductsReduced,
    getStockAndPrice: getAvailabilityAndPrices,
    getBreadcrumb,
    breadcrumbs,
  };
};
