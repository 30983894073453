import {
  computed, ref, ssrRef, useContext, useRoute,
} from '@nuxtjs/composition-api';
import { Locale } from '@nuxtjs/i18n';

import { useBFF } from '../useBFF/useBFF';
import { useNavbarCategories } from './useNavbarCategories';

export interface Category {
  id: string;
  orderHint: string;
  description: string;
  name: string;
  slug: string;
  slugAllLocales: Record<Locale, string>;
  canonicalUrl: string;
  key: string;
  metaTitle: string;
  parent: {
    slugAllLocales: Record<Locale, string>;
  },
  metaDescription: string;
  headlineTitle: string;
  isHidden: boolean;
  hideChartSize: boolean;
  childrenItems: Category[];
  saleMetadata: {
    metaTitle?: string;
    metaDescription?: string;
    headline?: string;
    description?: string;
  };
  redirectionRules?: {
    statusCode: number;
    redirectUrl: string;
  };
}

const loading = ref(false);
const error = ref({
  load: null,
});
const result = ssrRef<Category>(undefined, 'category');

const useCategory = () => {
  const { $ct } = useBFF();
  const { redirect, store, $config } = useContext();
  const route = useRoute();
  const { result: navbarCategories, load: loadNavbarCategories, flatCategories } = useNavbarCategories();

  const {
    slug_1: slug1,
    slug_2: slug2,
    slug_3: slug3,
    slug_4: slug4,
  } = route.value.params;
  const { isSale, isNew } = route.value.meta;

  const load = async () => {
    const [slug] = [slug4, slug3, slug2, slug1].filter(Boolean);
    if (!navbarCategories.value) {
      await loadNavbarCategories();
    }

    result.value = undefined;
    await store.commit('category/setCategory', undefined);

    if (!slug) { // not regular category page
      if (!isSale && !isNew) { // not sale or new, which means it's a magnolia page so no need to load category info
        return;
      }

      loading.value = true;

      try {
        const { data: category, errors } = await $ct.getCategory({ key: $config.category.rootKey, excludeChildren: true });

        if (errors.length) {
          throw new Error(errors.join(''));
        }

        result.value = category;

        error.value.load = null;

        await store.commit('category/setCategory', result.value);
      } catch (err) {
        error.value.load = true;
      } finally {
        loading.value = false;
      }
      return;
    }

    const key = flatCategories.value[slug];

    if (!key) {
      redirect('/');
      return;
    }

    if (key === result.value?.key) {
      return;
    }

    loading.value = true;
    try {
      const { data: category, errors } = await $ct.getCategory({ key });

      if (errors.length) {
        throw new Error(errors.join(''));
      }

      result.value = category;

      error.value.load = null;

      await store.commit('category/setCategory', result.value);
    } catch (err) {
      error.value.load = true;
    } finally {
      loading.value = false;
    }
  };

  return {
    result: computed<Category>(() => result.value ?? store.getters['category/getCategory']),
    loading: computed(() => loading.value),
    error: computed(() => error.value),
    load,
  };
};

export { useCategory };
