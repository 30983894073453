/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
import Vue from 'vue';
import { getFocusableChildren, isFocusable, moveFocus } from './focus-trap';

export const focusTrap = {
  bind(el) {
    Vue.nextTick(() => {
      el._focusableChildrenElements = getFocusableChildren(el);
    });
    el._keyHandler = (e) => {
      if (e.key === 'Tab') {
        if (!isFocusable(e, el._focusableChildrenElements)) {
          el._lastFocusedElement = e.target;
        }
      }
      moveFocus(e, el._focusableChildrenElements);
    };
    document.addEventListener('keydown', el._keyHandler);
  },
  componentUpdated(el) {
    Vue.nextTick(() => {
      el._focusableChildrenElements = getFocusableChildren(el);
    });
  },
  unbind(el) {
    if (el._lastFocusedElement) el._lastFocusedElement.focus();
    document.removeEventListener('keydown', el._keyHandler);
  },
};
