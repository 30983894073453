import { defineNuxtPlugin } from '@nuxtjs/composition-api';
import { CT_COOKIE_NAME } from '@vue-storefront/commercetools-api';

const errorCodes = ['invalid_token', 'invalid_grant', 'invalid_client'];

export default defineNuxtPlugin(({
  app, redirect, $cookies, req, route, next,
}) => {
  app.$vsf.$ct.client?.interceptors?.response.use((response) => response, (errorResponse) => {
    const { data: error } = errorResponse?.response || {};
    if ((errorCodes.includes(error?.networkError?.result?.error || error?.networkError?.error))) {
      if (process.server) {
        $cookies.set(CT_COOKIE_NAME, null, {
          expires: new Date(0),
          path: '/',
          domain: app.$domain.iamCookieDomain,
        });
        if (!route.query.qs) {
          redirect(302, req.url, { qs: `${Math.random()}` });
        }
        return next();
      }
      window.location.reload();
    }
    return Promise.resolve({ data: { data: {}, errors: [error] } });
  });
});
