import { useVSFContext } from '@vue-storefront/core';
import { BFFResponse } from '~/types/BFFResponse';

type CTApiEndpoints =
  | 'getReturnLabel'
  | 'requestPassword'
  | 'getOrderIdByNumberAndEmail'
  | 'legacyLogin'
  | 'getMyReturns'
  | 'addReturnInformation'
  | 'cancelReturn'
  | 'updateCart'
  | 'checkCartVersion'
  | 'getMe'
  | 'applyCoupon'
  | 'removeCoupon'
  | 'removeLoyaltyCoupon'
  | 'setNewsletterState'
  | 'removeItem'
  | 'updateQty'
  | 'deleteActiveCarts'
  | 'createCart'
  | 'addToCart'
  | 'getCategories'
  | 'getNavbarCategories'
  | 'getCategory'
  | 'saveCheckoutBillingInfo'
  | 'saveCheckoutShippingInfo'
  | 'getCategoryFacets'
  | 'updateOrderPaymentStatus'
  | 'getNewsletterState'
  | 'getShippingMethodsByCountry'
  | 'getShippingMethodsByCart'
  | 'saveShippingMethod'
  | 'sendEmail'
  | 'applyLoyaltyMembership'
  | 'unregisterLoyaltyMembership'
  | 'synchronizeUserWithCRM'
  | 'getAddressFromCRM'
  | 'loadPaymentMethods'
  | 'setPaymentOption'
  | 'createOrder'
  | 'getProductById'
  | 'getStockAndPrice'
  | 'getProducts'
  | 'getProductsReduced'
  | 'removeAddress'
  | 'updateAddress'
  | 'addUserAddress'
  | 'addUserShippingAddress'
  | 'addUserBillingAddress'
  | 'updateBirthDate'
  | 'registerUserViaMiddleware'
  | 'customerSignMeIn'
  | 'updateUser'
  | 'getMyOrder'
  | 'getMyOrderByOrderNumber'
  | 'getGuestOrder'
  | 'deleteUserProfile'
  | 'createShoppingList'
  | 'getShoppingList'
  | 'addToShoppingList'
  | 'removeFromShoppingList'
  // Shipping parcel shop
  | 'saveParcelShopShippingInfo'
  | 'startPaypalExpressCheckout'
  | 'userTransactionCount'
  | 'getRelatedProducts'
  | 'authenticate'
  | 'logout'
  ;

type CTApi = {
  [key in CTApiEndpoints]: <T = any, E = string[]>(...args: unknown[]) => Promise<BFFResponse<T, E>>
}

export const useBFF = () => {
  const { $ct } = useVSFContext();

  return {
    $ct: $ct.api as CTApi,
    // Separating since maybe in the future we'll have an integration just for Magnolia
    $magnolia: {
      fetchContent: <T>(args: { url: string; apiTimeout?: number; }): Promise<BFFResponse<T>> => $ct.api.fetchContent(args),
    },
    $internal: {
      logError: <T>(message: string, extra: unknown = {}): Promise<BFFResponse<T>> => $ct.api.logError(message, extra),
    },
  };
};
